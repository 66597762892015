import React from "react";
import { toast } from "react-toastify";

import Modal from "../../../components/Modals";
import requests from "../../../services/requests";

const ModalRemove = ({ actionModal, openModal, activeItem, handleSuccess }) => {
    return (
        <>
            {activeItem && (
                <Modal
                    onClose={actionModal}
                    size="xs:w-11/12 lg:w-8/12 min-h-80%"
                    show={openModal}
                    title={
                        <div className="flex items-center">
                            <h5 className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>Remover {activeItem.title}</h5>
                        </div>
                    }
                >
                    <p className="text-center">Esta ação é permanente</p>
                    <button
                        className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                        onClick={() => {
                            requests.deleteFaq(activeItem.identifier).then(() => {
                                toast.success("Removido com sucesso!");
                                handleSuccess();
                            });
                        }}
                    >
                        <>Remover</>
                    </button>
                </Modal>
            )}
        </>
    );
};
export default ModalRemove;
